import { params } from '../lib/constants'

export default async (url: string, startDate: string, endDate: string) => {
  const encoder = new TextEncoder()
  const data = encoder.encode(`${startDate}*${endDate}`)

  const hashBuffer = await crypto.subtle.digest('SHA-256', data)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
  return `${window.location.origin}${url}?${params.accessToken}=${hashHex}`
}
