export default {
  main: '/',
  access: {
    build: {
      link: '/access/build',
    },
    generated: '/access/generated',
    denied: '/access/denied',
  },
}
