import React from 'react'
import { navigate } from 'gatsby'

import Layout from '../../modules/ResourcesLayout'
import { CopyArea, Typography, Button } from '../../fragments'
import { Flex, Card } from '../../components'
import path from '../../path'
import { SecureAccessPageLink } from '../../lib/constants'
import createSecureUrl from '../../lib/createSecureUrl'

import '../../styles/access/generated.scss'

const Generated = (props: any) => {
  const [urls, setUrls] = React.useState<any>()

  const { state } = props.location
  React.useLayoutEffect(() => {
    const date = Date.now()

    if (!state?.options && !state?.endDate && !state?.startDate) {
      navigate(path.access.denied)
    }

    if (date > state?.expiresIn) navigate(path.access.denied)
  })

  React.useEffect(() => {
    state?.options.forEach(async (value: string) => {
      const url = await createSecureUrl(value, state?.endDate, state?.startDate)
      setUrls((prev: any) => {
        return {
          ...prev,
          [value]: url,
        }
      })
    })
  }, [])

  const openLinkHandler = (value: string) => {
    window.open(value)
  }

  return (
    <Layout title="Access Created">
      <div className="created-access">
        <Flex>
          <Flex.Column margin>
            {state?.options.map((value: string) => (
              <Card key={value} className="created-access__item">
                <Flex.Row wrap="nowrap">
                  <CopyArea content={String(urls?.[value])}>
                    <Typography>
                      {
                        SecureAccessPageLink.find(item => item.value === value)
                          ?.label
                      }
                    </Typography>
                  </CopyArea>
                  <Button
                    className="created-access__open"
                    onClick={() => openLinkHandler(value)}
                  >
                    Open
                  </Button>
                </Flex.Row>
              </Card>
            ))}
          </Flex.Column>
        </Flex>
      </div>
    </Layout>
  )
}

export default Generated
