import React from 'react'

import SEO from '../seo'
import { Cursor } from '../../fragments'

import './style.scss'

interface ILayout {
  children: React.ReactNode
  title?: string
}

const Layout: React.FC<ILayout> = ({ children, title = '' }) => {
  return (
    <>
      <SEO title={title} />
      <Cursor />
      <div className="resources-layout">
        <div className="resources-layout__container">{children}</div>
      </div>
    </>
  )
}

export default Layout
