export const params = {
  accessToken: 'accessToken',
}

export const SecureAccessPageLink = [
  {
    label: 'web terminal',
    value: '/web-terminal',
  },
  {
    label: 'polices',
    value: '/polices',
  },
  {
    label: 'Global legal',
    value: '/global-legal',
  },
  {
    label: 'Intellectual Property',
    value: '/Intellectual-property',
  },
  {
    label: 'Private projects',
    value: '/private-projects',
  },
  {
    label: 'Cloud access',
    value: '/cloud-access',
  },
  {
    label: 'Bash Scripts',
    value: '/bash-scripts',
  },
  {
    label: 'Code Base',
    value: '/code-base',
  },
  {
    label: 'Protocols',
    value: '/protocols',
  },
  {
    label: 'photos',
    value: '/photo',
  },
]
